html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size:0.92em;
  font-family: 'Times New Roman', Times, serif;
}

.container {
  position:relative;
  height: 100%;
  display: flex;
  min-height:1000px;
}

html {
  background-image: url('assets/images/florida-everglades.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
.newspaper {
  background-image: url('assets/images/newspaper.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  max-width:800px;
  max-height: 900px;
  display: flex;
  flex-direction: column;
  margin: 60px auto 0 auto;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  -webkit-filter: drop-shadow(5px 5px 15px #222);
  filter: drop-shadow(5px 5px 15px #222);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position:relative;
  z-index:999;
}
.meme {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.newspaper .top {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.volume {
  background-color: #000;
  color: #FFF;
  padding: 10px;
  white-space: nowrap;
}
.date {
  flex-grow: 1;
  text-align: center;
  padding: 10px;
}
.type {
  padding: 10px;
  white-space: nowrap;
  text-align: right;
}
.title {
  text-align: center;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  display: block;
}
.title {
  text-align: center;
  display: block;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
.title h1 {
  font-size: 3em;
  margin: 0;
  padding: 5px 0;
  font-family: 'Times New Roman', Times, serif;
}
.columns {
  margin-top:10px;
  display: flex;
  width: 100%;
}

.column-left, .column-right {
  font-size:0.8em;
  padding: 0 10px;
  width: 15%;
}
.column-left {
  padding-left:0;
}
.column-right {
  padding-right:0;
}
.column-left p,
.column-right p {
  text-align: justify;
}

.column-middle {
  width: 70%;
  border-left:1px solid #000;
  border-right:1px solid #000;
  padding: 0 10px;
}
.column-middle h2 {
  margin:0;
  font-size: 4em;
  text-align:center;
  font-family: 'Times New Roman', Times, serif;
}
.columns img {
  width:100%;
}
.sub-title {
  padding: 12px 0;
  border-bottom:1px solid #000;
  position: relative;
}
.page {
  color:#9a3431;
}
.column-middle img {
  width:100%;
}
.meme.alligator {
  position: absolute;
  bottom:100px;
  right:0;
  z-index:1;
}
.meme.alligator img {
  width:300px;
}
.meme.wtf {
  position: absolute;
  top:100px;
  left:25px;
  transform: rotate(-13deg);
  z-index:5;
}
.meme.dating {
  position: absolute;
  top:200px;
  right:55px;
  transform: rotate(13deg);
  z-index:1;
}
.meme.hunting {
  position: absolute;
  bottom:31px;
  left:55px;
  z-index:1;
}
.meme.willie {
  position: absolute;
  bottom:200px;
  left:65px;
  z-index:1;
}
.meme.dating img {
  width:300px;
}
.meme.hunting img {
  width:300px;
}
.find-your-man {
  padding:20px 80px 20px 80px;
  border-radius: 20px;
  background-color: #225c1a;
  width:10em;
  color: #FFF;
  text-decoration: none;
  display: block;
  text-align: center;
  margin: -50px auto 0 auto;
  font-size:2em;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.5);
  /* transform: rotate(-5deg); */
  transition: all 0.3s ease;
  position:relative;
  z-index: 10;
}
.find-your-man:hover {
  background-color: #218f13;
  /* transform: rotate(0deg); */
  box-shadow: 7px 5px 56px -14px #C3D900;
}
.find-your-man .birthdate {
  padding: 0.7em;
  margin-right:0.5em;
  border:0;
  border-radius: 1em;
  width: 12em;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
}
.find-your-man #searchButton {
  padding: 0.7em 2em;
  border:0;
  border-radius: 1em;
  background-color: #FFF;
  background-color: #AFE1A8;
  color: #225c1a;
  cursor: pointer;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.find-your-man #searchButton:hover {
  background-color: #266F1C;
  color: #FFF;
}
.find-your-man #result, .find-your-man .result {
  font-size:0.6em;
  margin-top:10px;
  text-align: center;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}
.find-your-man #result a, .find-your-man .result a {
  color:#FFF;
}
h4 {
  margin:0 0 10px 0;
  padding:0;
  font-weight: normal;
}
.column-bottom {
  margin-top:20px;
}
.column-bottom img {
  float:left;
  width:20vw;
  max-width:200px;
  padding-right:10px;
}
.column-bottom p {
  padding-left:10px;
}

.bg-marquee {
  position: fixed;
  bottom:0;
  background-color: #225c1a;
  height: 50px;
  width: 100%;
  background-image: url(assets/images/$floridaman.png);
  background-repeat: repeat-x;
  background-size: 150px auto;
  animation: marquee 1s infinite linear;
  z-index:1000;
}
.bg-marquee.top {
  top:0;
  bottom:auto;
}
.socials {
  position: absolute;
  right:0;
  display: flex;
  align-items: center;
  gap: 5px;
  top:0;
  background-color:#000;
  padding: 0 20px;
}
.socials-text {
  font-size: 1.5em;
  height: 35px;
  line-height: 35px;
  color:#FFF;
  background-color:#000;
  margin: 0 10px;
}
.socials img {
  width:2.6em;
  margin:2px 0 0 0;
}

.air-datepicker-global-container {
  z-index:1000;
}
.air-datepicker .datepicker--nav-title {
  visibility: hidden; /* Hides the year navigation title */
}
.air-datepicker-nav--title {
  pointer-events: none; /* Disables clicking on the navigation title */
}

@keyframes marquee {
  0% { background-position: 0; }
  100% { background-position: -150px; }
}

@media (max-width: 1068px) {
  html, body {
      font-size:1.267vw;
  }
  .newspaper {
      width: 73vw;
      height: 94vw;
  }
  .find-your-man {
      width:24vw;
      padding:2vw 5vw;
  }
  .find-your-man #searchButton {
      padding: 0.7em;
  }
  .find-your-man .birthdate {
      width: 10em;
  }
}

@media (max-width: 480px) {
  .newspaper {
    width: 95vw;
    height: 185vw;
  }
  html, body {
    font-size: 2.1vw;
  }
  .find-your-man {
      width: 41vw;
      padding:2vw 5vw;
  }
  .container {
      min-height: auto;
      overflow-x: hidden;
  }
  .find-your-man #searchButton {
      padding: 0.7em;
  }
  .find-your-man .birthdate {
      width: 8.3em;
  }
}